import _ from 'lodash'
import { useCallback, useContext } from 'react'
import i18next from 'i18next'
import { WebsiteContext } from '../services/websiteContext'

export const useTranslate = (method = 't') => {
  const websiteConfig = useContext(WebsiteContext)
  const { websiteKey } = websiteConfig
  i18next.init({
    lng: 'en',
    debug: false,
    resources: {
      en: {
        translation: require(`../config/translations/${websiteKey}/en.json`)
      }
    }
  })
  return useCallback((key, options) => {
    return i18next[method](key, options)
  })
}

export const getCurrentLanguage = () => i18next.language

export default useTranslate
